@import "bootstrap-config";
@import "bootstrap/bootstrap";


body {
    min-height: 100vh;
    width: 100vw;
    max-width:100%; 
}

.background-image {
    background-image: linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
        ), 
        url("/assets/img/background/3.png");
    background-size: cover;
    filter: blur(1px);
    -webkit-filter: blur(1px);

    /* Full height */
    height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

#landing {
    min-height: 100vh;
}

.default-bg {
    min-height: 100vh;

    background-image: linear-gradient(
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.5)
    ), 
    url("/assets/img/background/3.webp");

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}